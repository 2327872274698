import styled from '@emotion/styled';

export default styled.div`
    position: relative;

    .info-line-nav {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #FFFFFF;
        line-height: 120%;
        gap: 0.5em;
        font-size: 13px;

        .info-line-nav-content {
            &-status {
                color: darkgray;

                &.active {
                    color: #2fad43;
                    border-radius: 4px;
                }
            }

            &-phone {
                &.dark-color {
                    color: black;
                }
            }
        }

        &:hover {
            .info-line-nav-content-phone {
                position: relative;
                width: fit-content;

                &:before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    background-color: #bebebe;
                }
            }
        }
    }

    .info-line-drop-down {
        position: absolute;
        background-color: white;
        padding: 1em;
        left: 50%;
        transform: translate(-50%, -20px);
        top: 3em;
        border-radius: 0.5em;
        box-shadow: 0 0.6em 1em rgba(72, 74, 82, 0.1);
        opacity: 0;
        transition: opacity 200ms ease, transform 200ms ease;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.6em;
        z-index: 11;
        pointer-events: none;


        .status {
            color: tomato;

            &.enabled {
                color: #2fad43;
            }
        }

        a {
            color: white;
            text-decoration: none;
            background-color: #ba9449;
            padding: 0.5em 2em;
            border-radius: 0.4em;
        }

        &.active {
            opacity: 1;
            transform: translate(-85%, 0);
            pointer-events: all;
        }
    }
`;
