import { FunctionComponent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import BlogsLinks from '../BlogsLinks';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutNavigationDesktopPagesLinks: FunctionComponent<Props> = ({ pages }) => {
    if (pages.length <= 0) return null;

    return (
        <StyledComponent className="public-layout-navigation-desktop-pages-links">
            <div className="links-group">
                {pages.map((page, index) => (
                    <div
                        key={`${page.buttonProps.key}-${index}`}
                        className={classnames({
                            'link': true,
                            'has-sub-menu': page.subMenu,
                            'highlighted': page.buttonProps?.highlighted,
                        })}
                    >
                        {page?.buttonProps?.href ? (
                            <Link href={page.buttonProps.href}>
                                <a className="link__anchor">
                                    {page.buttonProps.children}
                                </a>
                            </Link>
                        ) : (
                            <span className="link__anchor">
                                {page.buttonProps.children}
                            </span>
                        )}

                        {page.subMenu && (
                            <>
                                <div className="chevron-icon">
                                    <img
                                        src="/images/home/arrow-down-nav.svg"
                                        alt="icon"
                                        width="10px"
                                        height="10px"
                                    />
                                </div>
                                <div className={classnames({
                                    'drop-down-container': true,
                                    'drop-down-container__dynamic-content': page.subMenu.dynamicContent,
                                })}
                                >
                                    {page.subMenu.dynamicContent ? (
                                        <BlogsLinks
                                            firstVisibleCategory={page.subMenu.firstVisibleCategory}
                                            categories={page.subMenu.categories}
                                        />
                                    ) : (
                                        <>
                                            {page.subMenu.sections.map((section) => (
                                                <Link
                                                    key={section.key}
                                                    href={section.href}
                                                >
                                                    <a className="drop-down-link">
                                                        {section.title}
                                                    </a>
                                                </Link>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationDesktopPagesLinks;
