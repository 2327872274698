import { filterKeys } from 'utils/object';

export function filterMarketingQuery(query: object): object {
    const result = {};

    Object.keys(query).forEach(key => {
        result[key.toLocaleLowerCase()] = query[key];
    });

    return filterKeys(result, {}, ['adid', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']);
}
