import Component from './component';

export interface Props {
    children?: any;
    size?: Sizes;
    className?: string;
}

export enum Sizes {
    XXSmall = 'xxsmall',
    XSmall = 'xsmall',
    Small = 'small',
    XXMedium = 'xxmedium',
    XMedium = 'xmedium',
    Medium = 'medium',
    Large = 'large',
}

Component.defaultProps = {
    children: null,
    size: Sizes.XXMedium,
    className: null,
};
export default Component;