import { connect } from 'react-redux';

import { State } from 'store/state';

import Component from './component';
import { NavigationElement, Props } from './types';

export const initialNavigationElements: NavigationElement = {
    logo: true,
    infoLine: true,
    profile: true,
    cart: true,
    pageLinks: true,
    zones: true,
};

const mapStateToProps = (state: State, ownProps?: Props): object => {
    return {
        state: {
            cart: state?.cart?.data,
            authToken: state?.user?.authToken,
        },
    };
};

Component.defaultProps = {
    navigationElementVisibility: {
        logo: true,
        infoLine: true,
        profile: true,
        cart: true,
        pageLinks: true,
        zones: true,
    },
};

export default connect(mapStateToProps, null)(Component);
