import { FunctionComponent } from 'react';
import classnames from 'classnames';

import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutInfoBar: FunctionComponent<Props> = ({
    className,
    bannerContent,
    bannerLink,
}) => {
    return (
        <StyledComponent
            className={classnames(
                'top',
                className
            )}
        >
            <LayoutContainer size={Sizes.XXMedium}>
                <div className="infobar-container">
                    <img
                        className="infobar-button"
                        src="/images/banner-button.svg"
                        alt="banner button"
                    />
                    <img
                        className="infobar-button-mobile"
                        src="/images/banner-button-black.svg"
                        alt="banner button"
                    />
                    <div className="infobar-content">
                        <a
                            href={bannerLink}
                            className="infobar-link"
                        >
                            <p className="infobar-headline">{bannerContent}</p>
                        </a>
                        <img
                            className="infobar-bg"
                            src="/images/banner-bg.svg"
                            alt="banner bg"
                        />
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicLayoutInfoBar;
