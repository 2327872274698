import { Navigation } from 'locales/types/components';

import Component from './component';

export interface Props {
    translations: Navigation;
    isScrolledTop: boolean;
    isMobile?: boolean;
    mobileMenu?: boolean
}


export const exceptionDays = [
    '01.05.2024',
    '03.05.2024',
    '19.05.2024',
    '30.05.2024',
    '15.08.2024',
    '01.11.2024',
    '11.11.2024',
    '25.12.2024',
    '26.12.2024',
    '01.01.2025',
    '06.01.2025',
    '20.04.2025',
    '21.04.2025',
    '01.05.2025',
    '03.05.2025',
    '08.06.2025',
    '19.06.2025',
    '15.08.2025',
    '01.11.2025',
    '11.11.2025',
    '25.12.2025',
    '26.12.2025',
];


Component.defaultProps = {};

export default Component;
