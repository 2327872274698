import Cart from 'models/Cart';

export interface Props {
    state: {
        cart: Cart;
        authToken: string;
    }
    className: string,
    isScrolledTop: boolean;
    navigationElementVisibility?: NavigationElement;
}

export interface NavigationElement {
    logo: boolean;
    infoLine: boolean;
    profile: boolean;
    cart: boolean;
    pageLinks: boolean;
    zones: boolean;
}

export enum Variants {
    Dark = 'dark',
    Light = 'light',
}
