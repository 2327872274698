import { Option } from 'types/options';

export const chunk = (array: any[], chunkSize: number): any[] => {
    return array
        .reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []);
};

export function dedupe(array: any[]): any[] {
    return array.filter(function(item, pos) {
        return array.indexOf(item) == pos;
    });
}

export function dedupeObjects(array: any[], key: string): any[] {
    return array.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t[key] === item[key]
        ))
    );
}

export const groupByKey = (key: string, list: any[]): any[] => {
    const groups = [];

    const keys = list.map(element => ({ [key]: element[key] }));

    dedupeObjects(keys, key).forEach(groupKey => {
        groups.push({
            [key]: groupKey[key],
            values: list
                .map(element => {
                    if(element[key] === groupKey[key]) return element;
                })
                .filter(element => element !== undefined),
        });
    });

    return groups;
};

export const filterOptionsByTypes = (options: any[], types: string[]): any[] => {
    return options.filter((option: Option<any>) => types.includes(option.value));
};