import { FunctionComponent } from 'react';
import getConfig from 'next/config';

import NewImage from 'components/layout/NewImage';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutLogo: FunctionComponent<Props> = ({ logo }) => {
    const { publicRuntimeConfig } = getConfig();

    if (publicRuntimeConfig.APP_LOCALE === 'pl') {
        return (
            <StyledComponent className="layout-logo">
                <NewImage
                    className="logo-img depilacja-logo"
                    src={logo}
                    alt="Depilacja.pl"
                    width="190px"
                    height="45px"
                />
            </StyledComponent>
        );
    }

    if (publicRuntimeConfig.APP_LOCALE === 'en') {
        return (
            <StyledComponent className="layout-logo">
                <NewImage
                    className="logo-img envie-logo"
                    src={logo}
                    alt="Envie logo"
                    width="136px"
                    height="60px"
                />
            </StyledComponent>
        );
    }
};

export default LayoutLogo;
