import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
background-color: #272626;

	.infobar-container {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;

		.infobar-content {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			.infobar-link {
				text-decoration: none;
				margin-left: 1.25em;
			}
			.infobar-bg {
				display: block;
				justify-items: flex-end;
			}
		}

		.infobar-button-mobile {
			display: none;
		}

		.infobar-button {
			display: block;
		}

		.infobar-headline {
			font-size: 1.25em;
			color: #fff;
		}

	}

	@media all and (max-width: ${vars.tabletL}) {
		width: 100%;
		background-color: #B99B60;
		color: #fff;
		height: 43px;

		.infobar-container {
			height: 43px;
			.infobar-headline {
				font-size: 1.25em;
			}
			.infobar-button {
				display: none;
			}
			.infobar-button-mobile {
				display: block;
				width: 3em;
				height: 2em;
			}
			.infobar-content {
				.infobar-bg {
					display: none;
				}
			}
		}
	}
`;