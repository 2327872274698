export interface Props {
    sections?: Section[];
    onSubmenuClick?: Function;
    layout?: Layout;
    dynamicContent?: boolean;
    categories?: {
        endermology: string;
        epilation: string;
        technology: string;
        skinCare: string;
        hairRemoval: string;
        endoshape: string;
    }
    firstVisibleCategory?: string;
}

export interface Section {
    key: string;
    type: SectionType;
    title: string;
    image: string;
    href?: string;
    elements?: SectionElement[];
    redirect?: RedirectType;
}

export interface SectionElement {
    key: string;
    href: string;
    title?: string;
    subTitle?: string;
}

export enum RedirectType {
    Page = 'page',
}

export enum SectionType {
    Default = 'default',
}

export enum Layout {
    Default = 'default',
    Wide = 'wide',
}
