import Component from './component';

export interface Props {
    size?: Sizes;
    color?: Colors;
    position?: Positions;
    contrastBg?: boolean;
    delayAnimation?: boolean;
}

export enum Positions {
    Absolute = 'absolute',
    Relative = 'relative',
}

export enum Sizes {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

export enum Colors {
    Default = 'default',
    White = 'white',
    Black = 'black',
}

Component.defaultProps = {
    size: Sizes.Medium,
    position: Positions.Absolute,
};

export default Component;
