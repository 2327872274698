import { FunctionComponent, useContext } from 'react';

import DeviceContext from 'components/context/Device';

import NavigationDesktop from '../Navigation/Desktop/component';

import NavigationMobile from './Mobile/component';
import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutNavigation: FunctionComponent<Props> = (props) => {
    const deviceContext = useContext(DeviceContext);

    return (
        <StyledComponent className="public-layout-navigation">
            {deviceContext.isMobileNavigation ? <NavigationMobile {...props} /> : <NavigationDesktop {...props} />}
        </StyledComponent>
    );
};

export default PublicLayoutNavigation;
