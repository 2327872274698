import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { IMAGE_PLACEHOLDER } from 'consts/images';
import { hasImageFormat, imageResizer } from 'utils/image';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutNewImage: FunctionComponent<Props> = ({
    fileUpload,
    rounded,
    className,
    resizerConfig,
    lazy,
    ...restProps
}) => {
    const avifFormat: boolean = hasImageFormat(restProps?.src, ['avif']) || fileUpload?.mimeType === 'image/avif';
    if (avifFormat) restProps.src = IMAGE_PLACEHOLDER;

    // images passed as src
    if (restProps?.src && !fileUpload) {
        return (
            <StyledComponent
                className={classnames(
                    'layout-new-image',
                    className,
                    { rounded }
                )}
                loading={lazy ? 'lazy' : 'eager'}
                {...restProps}
                src={resizerConfig
                    ? imageResizer(restProps.src, resizerConfig)
                    : restProps.src
                }
                srcSet={resizerConfig
                    ? imageResizer(restProps.src, resizerConfig, true)
                    : restProps.src
                }
            />
        );
    }

    if (!fileUpload?.imageUrl) return null;

    // fileUpload image
    return (
        <StyledComponent
            className={classnames(
                'layout-new-image',
                className,
                { rounded }
            )}
            src={avifFormat
                ? fileUpload.imageUrl
                : resizerConfig
                    ? imageResizer(fileUpload.imageUrl, resizerConfig)
                    : fileUpload.imageUrl
            }
            srcSet={avifFormat
                ? undefined
                : resizerConfig
                    ? imageResizer(fileUpload.imageUrl, resizerConfig, true)
                    : undefined
            }
            alt={fileUpload?.alt}
            title={fileUpload?.title}
            loading={lazy ? 'lazy' : 'eager'}
        />
    );
};

export default LayoutNewImage;
