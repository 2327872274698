import styled from '@emotion/styled';

export default styled.div`
    .public-layout-navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .p-0 {
        padding: 0;
    }
`;
