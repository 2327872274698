import styled from '@emotion/styled';

export default styled.div`
    .links-group {
        display: flex;

        .link {
            position: relative;
            color: #FFFFFF;
            font-weight: 300;
            font-size: 14px;
            display: flex;
            align-items: center;

            &:first-of-type {
                .link__anchor {
                    padding-left: 0;
                }
            }

            &__anchor {
                display: flex;
                align-items: center;
                padding: 0.8em 1em;
                text-decoration: none;
                color: currentColor;
                text-align: center;
                cursor: pointer;
            }

            &.highlighted {
                a {
                    padding: 0.5em 0.5em 0.5em;
                    background-color: #ba9448;
                    border-radius: 0.2em;

                    &:before {
                        content: "";
                        background: url("/images/home/gift-solid.svg") no-repeat;
                        filter: brightness(0) invert(1);
                        display: block;
                        width: 18px;
                        height: 18px;
                        float: left;
                        margin: 0 6px 0 0;
                    }
                }
            }

            &.has-sub-menu {
                .link__anchor {
                    padding-right: 0;
                }
            }

            .chevron-icon {
                cursor: pointer;
                padding: 0 1em 0 0.5em;
            }

            .drop-down-container {
                position: absolute;
                background-color: #FFFFFF;
                border-radius: 0.6em;
                display: flex;
                flex-direction: column;
                padding: 0.6em;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -20px);
                opacity: 0;
                pointer-events: none;
                transition: opacity 200ms ease, transform 200ms ease;
                box-shadow: rgb(3 27 78 / 10%) 0 .2em .4em;
                z-index: 2;

                .drop-down-link {
                    color: #585858;
                    font-size: 13px;
                    padding: 0.5em;
                    white-space: nowrap;
                    text-decoration: none;
                    border-radius: 0.6em;

                    &:hover {
                        background-color: #CFB799;
                        color: #FFFFFF;
                    }
                }

                &__dynamic-content {
                    padding: 1.6em 1.6em 1.4em;
                    transform: translate(-30%, -20px);
                    left: 0;
                }
            }

            &:not(&.has-sub-menu) {
                &:after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background-color: #BA9449;
                    border-radius: 2px;
                    left: 1em;
                    right: 1em;
                    bottom: 0;
                    opacity: 0;
                }

                &:hover {
                    &:after {
                        opacity: 1;
                    }
                }

                &:first-of-type {
                    &:after {
                        left: 0;
                    }
                }
            }

            &:hover {
                .drop-down-container {
                    opacity: 1;
                    pointer-events: all;
                    transform: translate(-50%, 0px);

                    &__dynamic-content {
                        transform: translate(-30%, 0px);
                    }
                }
            }
        }
    }
`;
