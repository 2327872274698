import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    opacity: 0;
    width: 100%;
    animation: showSpinner ease-in-out 300ms forwards;

    &.delay {
        animation-delay: 300ms;
    }

    &.position-absolute {
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 100%;
        z-index: 3;
        top: 0;
        left: 0;

        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.position-relative {
        position: relative;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;

        .loader {
            position: relative;
        }
    }

    &.contrast-background {
        .loader {
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 50%;
        }
    }

    &.size-small {
        .loader {
            width: 100px;
            height: 100px;
        }
    }

    &.size-medium {
        .loader {
            width: 120px;
            height: 120px;
        }
    }

    &.size-large {
        .loader {
            width: 150px;
            height: 150px;
        }
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 90%;
        fill: none;
    }

    .point {
        animation: bounce 1s infinite ease-in-out;

        &.one {
            fill: ${variables.firstColorSpinner};
            animation-delay: 0s;
        }

        &.two {
            fill: ${variables.secondColorSpinner};
            animation-delay: 0.1s;
        }

        &.three {
            fill: ${variables.thirdColorSpinner};
            animation-delay: 0.2s;
        }
    }

    &.color-white {
        .point {
            &.one,
            &.two,
            &.three {
                fill: #ffffff;
            }
        }
    }

    @keyframes bounce {
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
    }

    @keyframes showSpinner {
        100% {
            opacity: 1;
        }
    }

`;

