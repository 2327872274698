import { FunctionComponent } from 'react';

import FooterElement from 'components/layout/FooterElement';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutFooterSection: FunctionComponent<Props> = ({ elements }) => {
    return (
        <StyledComponent className="layout-nav-section-simple">
            {elements
                .filter(element => element.visible !== false)
                .map(element => (
                    <FooterElement
                        customClass={`${element.key} footer-redirect`}
                        key={element.key}
                        {...element}
                    />
                ))}
        </StyledComponent>
    );
};

export default LayoutFooterSection;
