import styled from '@emotion/styled';

export default styled.img`
    position: relative;
    width: 100%;

    &.rounded {
        border-radius: 1em;
        overflow: hidden;
    }
`;