import Component from './component';

Component.defaultProps = {
    children: null,
    place: 'top',
    type: 'info',
    effect: 'solid',
    reactTooltipProps: {},
};

export default Component;