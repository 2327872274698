import getConfig from 'next/config';

// https://developers.cloudflare.com/images/image-resizing/url-format#options
export interface Options {
    width?: number;
    height?: number;
    dpr?: number,
    fit?: Fit,
    gravity?: Gravity,
    quality?: number,
    format?: Format,
    anim?: boolean,
    sharpen?: number,
    blur?: number,
    onerror?: string,
    metadata?: Metadata;
}

// Aspect ratio width and height

export enum Fit {
    ScaleDown = 'scale-down',
    Contain = 'contain',
    Cover = 'cover',
    Crop = 'crop',
    Pad = 'pad',
}

// Specifies the most important side
export enum Gravity {
    Auto = 'auto',
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right',
}

// Allows serving of the WebP or AVIF format to browsers that support it
export enum Format {
    Auto = 'auto',
}

// Controls amount of invisible metadata (EXIF data) that should be preserved
export enum Metadata {
    Keep = 'keep',
    Copyright = 'copyright',
    None = 'none',
}

export function imageResizer(url: string, options: Options, isSrcSet: boolean = false): string {
    const { publicRuntimeConfig } = getConfig();

    if (publicRuntimeConfig.APP_ENV === 'development' || url?.includes('.svg')) {
        return url;
    }

    const defaultOptions = {
        quality: 85,
        format: Format.Auto,
        metadata: Metadata.None,
    };
    const finalOptions: Options = {
        ...defaultOptions,
        ...options,
    };

    const mappedOptions = Object.keys(finalOptions).map(option => `${option}=${finalOptions[option]}`);
    url = `/${url}`;
    url = url.replace('//', '/');

    //TODO: customOption for srcSet
    if(isSrcSet && options['width'] > 300) {
        const filteredMappedOptions = mappedOptions.filter(mappedOption => !mappedOption.includes('width'));
        const srcSet = `/cdn-cgi/image/${filteredMappedOptions},width=320${url} 320w,
            /cdn-cgi/image/${filteredMappedOptions},width=640${url} 640w,
            /cdn-cgi/image/${filteredMappedOptions},width=960${url} 960w,
            /cdn-cgi/image/${filteredMappedOptions},width=1280${url} 1280w,
            /cdn-cgi/image/${filteredMappedOptions},width=1920${url} 1920w`;

        return `${publicRuntimeConfig.APP_URL}${srcSet}`;
    }

    return `${publicRuntimeConfig.APP_URL}/cdn-cgi/image/${mappedOptions}${url}`;
}

export function hasImageFormat(imageUrl: string, formats: string[]): boolean|null {
    if (formats.some(el => imageUrl?.includes(el))) {
        return true;
    }

    return false;
}
