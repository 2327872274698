import { FunctionComponent, useContext, useMemo } from 'react';
import getConfig from 'next/config';
import Link from 'next/link';
import classnames from 'classnames';

import { Locales } from 'types/locale';

import Article from 'models/Article';
import ArticleCategory from 'models/ArticleCategory';

import { groupByKey } from 'utils/array';

import MarketContext from 'components/context/Market';
import NewImage from 'components/layout/NewImage';

import StyledComponent from './styles';
import { Props } from './types';

interface ArticleWithCategorySlug extends Article {
    categorySlug: string;
}

interface ArticlesByCategory {
    categorySlug: string,
    values: ArticleWithCategorySlug[],
    category?: ArticleCategory,
    firstArticleByCategory?: Article,
}

const PublicLayoutNavigationDesktopBlogsLinks: FunctionComponent<Props> = ({
    categories,
    firstVisibleCategory,
}) => {
    const { publicRuntimeConfig } = getConfig();
    const marketContext = useContext(MarketContext);

    const queryCategories = (
        publicRuntimeConfig.APP_LOCALE === Locales.Pl
            ? [categories?.endermology, categories?.epilation, categories?.technology]
            : [categories?.hairRemoval, categories?.endoshape, categories?.skinCare]
    ).filter(category => category);

    const articlesByCategory: ArticlesByCategory[] = useMemo(() => {
        return groupByKey(
            'categorySlug',
            marketContext?.articles?.map((article: Article): ArticleWithCategorySlug => ({
                ...article,
                categorySlug: article?.category?.locale?.slug,
            }))
        )
            .filter((categoryArticles): boolean => {
                return categoryArticles?.values?.length > 0 && queryCategories.includes(categoryArticles.categorySlug);
            })
            .map((categoryArticles: ArticlesByCategory) => {
                const firstArticleCategory = categoryArticles?.values[0];
                let firstArticleByCategory: Article;

                if (categoryArticles.categorySlug === firstVisibleCategory && categoryArticles.values.length > 0) {
                    firstArticleByCategory = categoryArticles.values.shift();
                }

                return {
                    ...categoryArticles,
                    category: firstArticleCategory?.category,
                    firstArticleByCategory,
                };
            });
    }, []);

    const articleByCategory = articlesByCategory.find((article) => article?.categorySlug === firstVisibleCategory);
    const firstArticle = {
        title: articleByCategory?.firstArticleByCategory?.locale?.title || '',
        href: articleByCategory?.firstArticleByCategory?.href || '',
        image: articleByCategory?.firstArticleByCategory?.locale?.image || null,
    };

    return (
        <StyledComponent className="public-layout-navigation-desktop-blogs-links">
            <Link
                href={firstArticle?.href}
                prefetch={false}
            >
                <a
                    className={classnames(
                        'sub-menu-section',
                        'type-default',
                        firstArticle.image && 'sub-menu-section-image',
                    )}
                >
                    {firstArticle.image && (
                        <NewImage
                            className="sub-menu-section-image"
                            fileUpload={firstArticle.image}
                            resizerConfig={{ width: 300 }}
                            alt="blog-image"
                        />
                    )}
                    {firstArticle.title && (
                        <div
                            className="sub-menu-section-title"
                            dangerouslySetInnerHTML={{ __html: firstArticle.title }}
                        />
                    )}
                </a>
            </Link>
            {articlesByCategory.map(({ categorySlug, values, category }) => (
                <div
                    key={categorySlug}
                    className="sub-menu-section"
                >
                    <div className="sub-menu-section-title">
                        {category?.locale?.name}
                    </div>
                    <div className="sub-menu-section-elements">
                        {values.length > 0 && values.map(({ id, locale, href }, index) => index < 5 && (
                            <Link
                                key={id}
                                href={href}
                                prefetch={false}
                            >
                                <a className="sub-menu-section-element">
                                    {locale.title}
                                </a>
                            </Link>
                        ))}
                    </div>
                </div>
            ))}
        </StyledComponent>
    );
};

export default PublicLayoutNavigationDesktopBlogsLinks;
