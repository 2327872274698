import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutSpinnerNew: FunctionComponent<Props> = ({ position, size, contrastBg, color, delayAnimation }) => {
    return (
        <StyledComponent
            className={classnames(
                'layout-spinner',
                contrastBg && 'contrast-background',
                [`color-${color}`],
                [`position-${position}`],
                [`size-${size}`],
                delayAnimation && 'delay',
            )}
        >
            <div className="loader">
                <svg
                    viewBox="0 0 120 120"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <circle
                            className="point one"
                            cx="45"
                            cy="70"
                            r="5"
                        />
                        <circle
                            className="point two"
                            cx="60"
                            cy="70"
                            r="5"
                        />
                        <circle
                            className="point three"
                            cx="75"
                            cy="70"
                            r="5"
                        />
                    </g>
                </svg>
            </div>
        </StyledComponent>
    );
};

export default LayoutSpinnerNew;
