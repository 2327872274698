import { FunctionComponent } from 'react';
import classnames from 'classnames';

import Button, { ButtonElements, ButtonStyles } from 'components/layout/Button';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutFooterElement: FunctionComponent<Props> = ({
    buttonProps,
    customClass,
}) => {
    return (
        <StyledComponent
            className={classnames(
                'layout-nav-element',
                customClass,
            )}
        >
            <div className="button-wrapper">
                <Button
                    element={buttonProps.href && buttonProps.href !== '#' && ButtonElements.Anchor || ButtonElements.Button}
                    style={ButtonStyles.None}
                    {...buttonProps}
                />
            </div>
        </StyledComponent>
    );
};

export default LayoutFooterElement;
