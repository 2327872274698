import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media all and (max-width: ${variables.tabletL}) {
        .logo-img {
            &.depilacja-logo {
                width: calc(190px * 0.7);
                height: calc(45px * 0.7);
            }

            &.envie-logo {
                width: calc(136px * 0.7);
                height: calc(60px * 0.7)
            }
        }
    }

    @media all and (max-width: ${variables.mobileS}) {
        .logo-img {
            &.depilacja-logo {
                width: calc(190px * 0.6);
                height: calc(45px * 0.6);
            }

            &.envie-logo {
                width: calc(136px * 0.6);
                height: calc(60px * 0.6)
            }
        }
    }
`;
