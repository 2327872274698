import styled from '@emotion/styled';

export default styled.div`
    position: relative;

    .button-wrapper {
        .layout-button {
            &.element-anchor {
                .button {
                    color: white;
                    transition: .2s color ease-in-out;

                    .button-content {
                        font-weight: 300;
                        opacity: 0.9;
                    }
                }
            }
        }
    }

    &.footer-redirect {
        @media (pointer: fine) {
            &:hover {
                .button-wrapper {
                    .layout-button {
                        &.element-anchor {
                            .button {
                                .button-content {
                                    text-shadow: 0 0 1px white;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
