import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;

    @media all and (max-width: ${variables.tabletL}) {
        gap: unset;
    }
`;
